<template>
  <ion-page>
    <Header title="Ranking" />
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <div>
        <ion-card>
          <ion-card-header>
            <div style="display: flex; align-items: center;">
              <ion-card-subtitle style="color: #737373;"
                >Temporada:</ion-card-subtitle
              >
              <ion-select
                :value="selectedSeason"
                :disabled="loading"
                @ionChange="selectedSeason = $event.target.value"
              >
                <ion-select-option
                  v-for="item in allSeason"
                  color="danger"
                  :key="item.cdId"
                  :value="item.cdId"
                  >{{ `${item.descTemporada}` }}</ion-select-option
                >
              </ion-select>
            </div>
            <ion-icon
              :icon="informationCircle"
              color="medium"
              style="font-size: 25px;"
              @click="setOpenModalRankingLegend(true)"
            ></ion-icon>
          </ion-card-header>
          <ion-card-content>
            <ion-searchbar
              class="nickname-color"
              animated
              debounce="1000"
              placeholder="Buscar jogador"
              :value="searchValue"
              @ionChange="searchValue = $event.target.value"
            ></ion-searchbar>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-header>
            <ion-card-subtitle style="color: #737373;"
              >Jogador</ion-card-subtitle
            >

            <ion-select
              :interface-options="customActionSheetOptions"
              interface="action-sheet"
              :value="selectedMetric"
              :disabled="loading"
              @ionChange="selectedMetric = $event.target.value"
            >
              <ion-select-option value="13">Aproveitamento</ion-select-option>
              <ion-select-option value="11">Eficiência</ion-select-option>
              <ion-select-option value="4">Gols</ion-select-option>
              <ion-select-option value="5">Assitências</ion-select-option>
              <ion-select-option value="6">Gols de penaltis</ion-select-option>
              <ion-select-option value="1">Vitórias</ion-select-option>
              <ion-select-option value="2">Derrotas</ion-select-option>
              <ion-select-option value="3">Empates</ion-select-option>
              <ion-select-option value="9">Gols contra</ion-select-option>
              <ion-select-option value="12">Jogos</ion-select-option>
            </ion-select>
          </ion-card-header>
          <ion-card-content>
            <div
              class="loading-ranking"
              v-if="loading"
              style="display: flex; align-items: center; justify-content: center; background-color: white; margin: auto; padding-top: 80px; padding-bottom: 80px;"
            >
              <ion-spinner :color="theme"></ion-spinner>
            </div>
            <ion-list v-else>
              <h1
                class="h1-ranking"
                v-if="!rankingC.length && !searchValue"
                style="font-size: 1rem; text-align: center; color: gray; background-color: white; margin: 0; margin: auto; padding-top: 25px; padding-bottom: 25px;"
              >
                Nenhum dado encontrado
              </h1>
              <div v-for="item in rankingC" :key="item.cdId">
                <ion-item lines="none" :style="getBorderColor(item.rowNum)">
                  <p class="p-ranking" style="width: 25px;">
                    {{ item.rowNum }}
                  </p>
                  <Avatar
                    class="avatar"
                    width="50"
                    height="50"
                    :imageUrl="item.imagemUrl"
                    eventOn
                    @onClick="setOpenModalPlayerProfile(item.cdId)"
                  />
                  <ion-label>
                    <h2 class="nickname-color">{{ item.apelido }}</h2>
                  </ion-label>
                  <p class="p-ranking">
                    {{
                      ["11", "13"].includes(selectedMetric)
                        ? (item.qtdValor > 100 ? "100" : item.qtdValor) + "%"
                        : item.qtdValor
                    }}
                  </p>
                </ion-item>
              </div>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </div>

      <ion-modal :is-open="isOpenModalRankingLegend">
        <ModalRankingLegend
          @onDidDismiss="setOpenModalRankingLegend(false)"
        ></ModalRankingLegend>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonModal,
} from "@ionic/vue";
import Header from "@/components/Layout/Header.vue";
import Avatar from "@/components/Layout/Avatar.vue";
import ModalRankingLegend from "@/components/Modals/ModalRankingLegend.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";

export default {
  name: "Ranking",
  mixins: [requestManager, computedManager],
  components: {
    IonContent,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonModal,
    Header,
    Avatar,
    ModalRankingLegend,
  },
  data() {
    return {
      loading: false,
      searchValue: "",
      playerId: 0,
      selectedMetric: "13",
      selectedSeason: null,
      isOpenModalRankingLegend: false,
    };
  },
  computed: {
    allSeason() {
      return this.$store.state.base.allSeason;
    },
    ranking() {
      return this.$store.state.base.ranking;
    },
    rankingC() {
      if (this.searchValue) {
        return this.ranking.filter((p) => {
          return `${p.nome}${p.apelido}`
            .toLowerCase()
            .includes(this.searchValue.toLowerCase());
        });
      } else {
        return this.ranking;
      }
    },
  },
  async mounted() {
    if (!this.playerInformation || !this.playerInformation.cdId) {
      const playerInformation = localStorage.getItem("playerInformation");
      this.$store.commit(
        "profile/setPlayerInformation",
        JSON.parse(playerInformation)
      );
    }

    this.loading = true;
    await this.init();
  },
  methods: {
    async init() {
      await this.getSeasonList();
    },
    async getSeasonList() {
      const response = await this.genericRequest("common/getAllSeason");
      if (typeof response != "string") {
        this.$store.commit("base/setAllSeason", response);
      } else {
        this.$store.commit("base/setAllSeason", []);
      }
      const lastSeason = this.allSeason[this.allSeason.length - 1];
      this.selectedSeason = lastSeason ? lastSeason.cdId : null;
    },
    async getRankingList() {
      const response = await this.genericRequest("common/getRanking", {
        metric: this.selectedMetric,
        cdIdSeason: this.selectedSeason,
      });
      if (typeof response != "string") {
        this.$store.commit("base/setRanking", response);
      } else {
        this.$store.commit("base/setRanking", []);
      }
    },
    getBorderColor(pIndex) {
      if (["3", "12"].includes(this.selectedMetric)) {
        return "border-left: 4px solid #cccccc; border-right: 4px solid #cccccc;";
      } else if (["2", "9"].includes(this.selectedMetric)) {
        if (pIndex < 3) {
          return "border-left: 4px solid #ea4335; border-right: 4px solid #ea4335;";
        } else if (pIndex >= 3 && pIndex < 7) {
          return "border-left: 4px solid #fa7b17; border-right: 4px solid #fa7b17;";
        } else if (pIndex >= 7 && pIndex < 12) {
          return "border-left: 4px solid #c9ca05; border-right: 4px solid #c9ca05;";
        } else if (pIndex >= 12) {
          return "border-left: 4px solid #34A853; border-right: 4px solid #34A853;";
        }
      } else {
        if (pIndex < 3) {
          return "border-left: 4px solid #34A853; border-right: 4px solid #34A853;";
        } else if (pIndex >= 3 && pIndex < 7) {
          return "border-left: 4px solid #c9ca05; border-right: 4px solid #c9ca05;";
        } else if (pIndex >= 7 && pIndex < 12) {
          return "border-left: 4px solid #fa7b17; border-right: 4px solid #fa7b17;";
        } else if (pIndex >= 12) {
          return "border-left: 4px solid #ea4335; border-right: 4px solid #ea4335;";
        }
      }
    },
    async doRefresh(event) {
      this.loading = true;
      await this.init();
      await this.getRankingList();
      event.target.complete();
      this.loading = false;
    },
    setOpenModalPlayerProfile(pPlayerId) {
      this.$store.commit("base/addPropsModal", {
        name: "ModalPlayerProfile",
        prop: pPlayerId,
      });

      this.$store.commit("base/addShowModal", "ModalPlayerProfile");
    },
    setOpenModalRankingLegend(state) {
      this.isOpenModalRankingLegend = state;
    },
  },
  watch: {
    async selectedSeason() {
      this.loading = true;
      await this.getRankingList();
      this.loading = false;
    },
    async selectedMetric() {
      this.loading = true;
      await this.getRankingList();
      this.loading = false;
    },
  },
};
</script>

<style scoped>
p {
  white-space: nowrap;
  text-align: center;
  font-weight: bold;
  margin: 0;
  margin-left: 5px;
  margin-right: 5px;
  color: #000000cc;
  font-size: 1rem;
}

ion-searchbar {
  --background: white;
  --box-shadow: none;
}

ion-card-header {
  display: flex;
  height: 37px;
  justify-content: space-between;
  align-items: center;
}

ion-list {
  padding: 0;
}

ion-item {
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  --background: white;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  --highlight-height: none;
  width: 100%;
  margin: auto;
  display: flex;
}

ion-card-content {
  padding: 0;
}

.container-ranking {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 100%;
}

.avatar {
  margin-right: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
