<template>
  <ion-page>
    <Header title="Legenda" showBackButton @onClick="$emit('onDidDismiss')" />

    <ion-content :fullscreen="true">
      <ion-card class="custom-ion-card">
        <ion-card-header>
          <ion-card-subtitle>Zona de classificação</ion-card-subtitle>
        </ion-card-header>

        <ion-card-content>
          <div class="legend-item">
            <p>
              <span class="square" style="background-color: #34A853;"></span
              >Jogadores em destaque
            </p>
            <p>
              <span class="square" style="background-color: #f9fa17;"></span
              >Jogadores medianos
            </p>
            <p>
              <span class="square" style="background-color: #fa7b17;"></span
              >Jogadores decadentes
            </p>
            <p>
              <span class="square" style="background-color: #ea4335;"></span
              >Jogadores com habilidades limitadas
            </p>
          </div>
        </ion-card-content>
      </ion-card>

      <!-- <ion-card class="custom-ion-card">
        <ion-card-header>
          <ion-card-subtitle>Mudança na classificação</ion-card-subtitle>
        </ion-card-header>

        <ion-card-content>
          <div class="legend-item">
            <p>
              <ion-icon color="success" :icon="arrowUpOutline" />
              Subiu
            </p>
            <p>
              <ion-icon color="danger" :icon="arrowDownOutline" />
              Caiu
            </p>
          </div>
        </ion-card-content>
      </ion-card>

      <ion-card class="custom-ion-card">
        <ion-card-header>
          <ion-card-subtitle>Últimas 4 partidas</ion-card-subtitle>
        </ion-card-header>

        <ion-card-content>
          <div class="legend-item">
            <p>
              <ion-icon color="success" :icon="checkmarkCircle"></ion-icon
              >Vitória
            </p>
            <p>
              <ion-icon color="medium" :icon="removeCircle"></ion-icon>Empate
            </p>
            <p>
              <ion-icon color="danger" :icon="closeCircle"></ion-icon>Derrota
            </p>
          </div>
        </ion-card-content>
      </ion-card> -->
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import {
  arrowDownOutline,
  arrowUpOutline,
  checkmarkCircle,
  closeCircle,
  removeCircle,
} from "ionicons/icons";
import Header from "@/components/Layout/Header.vue";
import { useBackButton } from "@ionic/vue";

export default {
  name: "RankingLegend",
  components: {
    IonContent,
    IonPage,
    Header,
  },
  computed: {
    arrowDownOutline() {
      return arrowDownOutline;
    },
    arrowUpOutline() {
      return arrowUpOutline;
    },
    checkmarkCircle() {
      return checkmarkCircle;
    },
    closeCircle() {
      return closeCircle;
    },
    removeCircle() {
      return removeCircle;
    },
  },
  mounted() {
    useBackButton(100, () => {
      this.$emit("onDidDismiss");
    });
  },
};
</script>
<style scoped>
p {
  margin: 0;
}

ion-icon {
  font-size: 18px;
}

ion-card-content {
  padding-top: 10px;
}

.square {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 10px;
}

.legend-item p {
  display: flex;
  align-items: center;
}

.legend-item ion-icon {
  margin-right: 10px;
}
</style>
